
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '@/styles/globals.css'
import 'react-toastify/dist/ReactToastify.css'

import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { SessionProvider } from 'next-auth/react'
import { DefaultSeo } from 'next-seo'
import useTranslation from 'next-translate/useTranslation'
import { ReactElement, ReactNode } from 'react'

import { usePersistLocaleCookie } from '@/hooks/usePersistLocaleCookie'
import { APP_NAME, SITE_URL } from '@/res/consts'
import { NAMESPACE_COMMON } from '@/res/namespaces'
import { ROUTE_WIDGET_FOR_DOMAIN } from '@/res/routes'

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

declare global {
  interface Window {
    UztelecomWidget: any
  }
}

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  usePersistLocaleCookie()

  const getLayout = Component.getLayout ?? ((page) => page)
  const router = useRouter()
  const { t } = useTranslation(NAMESPACE_COMMON)
  const isWidgetPage =
    router.pathname === '/widget_for_domain/[domain_name]' ||
    router.pathname === ROUTE_WIDGET_FOR_DOMAIN

  return (
    <>
      <DefaultSeo
        title={t('default_seo_title')}
        titleTemplate={`%s | ${APP_NAME}`}
        description={t('default_seo_description')}
        openGraph={{
          type: 'website',
          url: SITE_URL + router.asPath,
          images: [
            {
              url: '/assets/logo.png',
              alt: t('default_seo_title'),
            },
          ],
        }}
      />
      {!isWidgetPage ? (
        <Script
          src="https://uztelecom.uz/js/uztelecom-widget.js?2022-10-27"
          strategy="afterInteractive"
          onLoad={() => {
            setTimeout(() => {
              //eslint-disable-next-line
              // @ts-ignore
              window.UztelecomWidget = new UztelecomWidget({
                target: document.body,
                props: {
                  project_type: 'uzdc',
                },
              })
            }, 1000)
          }}
        />
      ) : null}

      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <SessionProvider>
        {getLayout(<Component {...pageProps} />)}
      </SessionProvider>
    </>
  )
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  