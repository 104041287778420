export const ROUTE_LOGIN = '/login'
export const ROUTE_REGISTRATION = '/registration'
export const ROUTE_PROFILE = '/profile'
export const ROUTE_PERSONAL_INFO = '/personal-information'
export const ROUTE_ACTIVE_SUBSCRIPTIONS = '/active-subscriptions'
export const ROUTE_SETTINGS = '/settings'
export const ROUTE_IDENTIFICATION = '/identification'
export const ROUTE_RESET_PASSWORD = '/reset_password'
export const ROUTE_RECOVER_PASSWORD = '/recovery_password'
export const ROUTE_PRIVACY_POLICY = '/privacy-policy'
export const ROUTE_SERVICES = '/services'
export const ROUTE_DNS_MANAGEMENT = '/services/dns/management'
export const ROUTE_ORDER = '/order'
export const ROUTE_PAYMENT = '/payment'
export const ROUTE_ADMIN = '/admin'
export const ROUTE_ADMIN_APPEALS = '/admin/appeals'
export const ROUTE_WIDGET_FOR_DOMAIN = '/widget_for_domain'
export const ROUTE_TELEGRAM_LOGIN = '/telegram-login'
export const ROUTE_SEND_APPLICATION = '/send_application'

export const ROUTE_NEWS = '/news'
