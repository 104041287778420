export const NAMESPACE_HOME_PAGE = 'homepage'
export const NAMESPACE_AUTH = 'auth'
export const NAMESPACE_COMMON = 'common'
export const NAMESPACE_VERIFY_EMAIL = 'verify_email'
export const NAMESPACE_PROFILE_PAGE = 'profile_page'
export const NAMESPACE_IDENTIFICATION_PAGE = 'identification_page'
export const NAMESPACE_PERSONAL_INFO_PAGE = 'personal_info_page'
export const NAMESPACE_HOSTING = 'hosting'
export const NAMESPACE_DOMAIN = 'domain_page'
export const NAMESPACE_PAYMENT = 'payment_page'
export const NAMESPACE_MANAGEMENT = 'dns_management_page'
export const NAMESPACE_SUBSCRIPTION_PAGE = 'active_subscription_page'
export const NAMESPACE_ORDER = 'order_page'
export const NAMESPACE_LANDING_PAGE = 'landing_page'
export const NAMESPACE_SETTINGS_PAGE = 'settings_page'
export const NAMESPACE_ERRORS = 'errors'
export const NAMESPACE_RESET_TOKEN = 'reset_token'
export const NAMESPACE_PRIVACY_POLICY = 'privacy_policy'
export const NAMESPACE_RESET_PASSWORD = 'reset_password'
export const NAMESPACE_REGISTRATION = 'registration'
export const NAMESPACE_LOGIN = 'login'
export const NAMESPACE_VDC = 'vdc'
export const NAMESPACE_ADMIN = 'admin'
export const NAMESPACE_WIDGET = 'widget_for_domain'
export const NAMESPACE_SEND_APPLICATION = 'send_application'
export const NAMESPACE_S3_BUCKET = 's3_bucket'
