export const APP_NAME = 'UZTELECOM'
export const SITE_URL = 'https://uzdc.uz'

export const servicesSlug = {
  hosting: 'web-hosting',
  dns: 'dns',
  vdc: 'vdc',
  s3: 's3-bucket',
}

export const paymentProviders = {
  CLICK: 'click',
  PAYME: 'payme',
  // APELSIN: 'apelsin',
}

export const typeRecords = ['A', 'AAAA', 'CNAME', 'MX', 'TXT']

export const CONTACT_NUMBER = '71 100 33 10'
