module.exports = {
  locales: ['ru', 'uz', 'en'],
  defaultLocale: 'ru',
  pages: {
    '*': ['common'],
    '/': ['landing_page'],
    '/404': ['landing_page'],
    '/registration': ['auth', 'errors', 'registration'],
    '/telegram-login': ['auth'],
    '/reset_password': ['auth', 'errors', 'reset_password'],
    '/recovery_password/[reset_token]': ['auth', 'errors', 'reset_token_page'],
    '/login': ['auth', 'errors', 'login'],
    '/email_verification/[signature]': ['verify_email'],
    '/profile': ['profile_page', 'hosting'],
    '/identification': ['identification_page', 'profile_page'],
    '/face-id': ['identification_page'],
    '/personal-information': ['personal_info_page'],
    '/services/web-hosting': ['hosting'],
    '/services/dns': ['domain_page'],
    '/payment/[uid]': ['payment_page'],
    '/services/dns/[domain_name]': ['domain_page'],
    '/order': ['order_page'],
    '/services/vdc': ['vdc'],
    '/settings': ['settings_page', 'errors'],
    '/send_application': ['send_application'],
    '/test-table': ['send_application'],
    '/privacy-policy': ['privacy_policy'],
    '/services/dns/management': ['dns_management_page'],
    '/services/dns/management/[id]': ['dns_management_page'],
    '/active-subscriptions': ['active_subscription_page'],
    '/privacy-policy/[id]': ['privacy_policy'],
    '/admin': ['admin'],
    '/admin/appeals/[id]': ['admin'],
    '/admin/appeals': ['admin'],
    '/news/[slug]': ['landing_page'],
    '/widget_for_domain': ['widget_for_domain'],
    '/widget_for_domain/[domain_name]': ['widget_for_domain'],
    '/services/s3-bucket': ['s3_bucket'],
  },
}
